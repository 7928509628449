import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import styles from './header.module.scss';
import { NavLink, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logo from '../../assets/img/shapeshifter_full.svg';

const className = "nav-link px-2 text-white";
const activeClassName = className + " active";

const Header = () => {
	return (
		<header className="p-1 text-bg-dark">
			<Container>
				<Row>
					<Col xxl={2} className="align-items-start">
						<Link to="/" className="align-items-center">
							<Image fluid={true} className={styles.logo} src={logo}/>
						</Link>
					</Col>
					<Col xxl={10}>
						<ul className="nav justify-content-end">
							{/*<li><NavLink end to="/" className={({ isActive }) => isActive ? activeClassName : className}>Home</NavLink></li>*/}
							{/*<li><NavLink to="/#features" className="nav-link px-2 text-white">Features</NavLink></li>*/}
							{/*<li><HashLink smooth to="/#features" className={className}>Features</HashLink></li>*/}
							{/*<li><NavLink to="/faq" className={({ isActive }) => isActive ? activeClassName : className}>FAQs</NavLink></li>*/}
							{/*<li><NavLink to="/about" className={({ isActive }) => isActive ? activeClassName : className}>About</NavLink></li>*/}
							{/*<li><a href="/" className="nav-link px-2 active">Home</a></li>*/}
							{/*<li><a href="/#features" className="nav-link px-2 text-white">Features</a></li>*/}
							{/*<li><a href="#" className="nav-link px-2 text-white">Pricing</a></li>*/}
							{/*<li><a href="/faq" className="nav-link px-2 text-white">FAQs</a></li>*/}
							{/*<li><a href="/about" className="nav-link px-2 text-white">About</a></li>*/}
						</ul>
					</Col>
				</Row>
			</Container>
		</header>
	);
}

export default Header