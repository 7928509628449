// export async function post(url, data)
// {
// 	var formBody = [];
// 	for(var property in data)
// 	{
// 		var encodedKey = encodeURIComponent(property);
// 		var encodedValue = encodeURIComponent(data[property]);
// 		formBody.push(encodedKey + "=" + encodedValue);
// 	}
// 	formBody = formBody.join("&");

// 	let result = await fetch(url,
// 	{
// 		method: 'POST',
// 		headers:
// 		{
// 			'Accept': 'application/json',
// 			'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
// 		},
// 		body: formBody
// 	});

// 	return result.json();
// }
export async function post(url, data)
{
	let result = await fetch(url,
	{
		method: 'POST',
		headers:
		{
			'Accept': 'application/json',
			'Content-type': 'application/json; charset=UTF-8'
		},
		body: JSON.stringify(data)
	});

	return result.json();
}

export default post;