import {Container, Row, Col} from 'react-bootstrap'
import Banner from '../components/banner/Banner'
import Features from '../components/features/Features'
import Avatars from '../components/avatars/Avatars'


const Home = () => (
	<main className="text-bg-dark">
		<Banner></Banner>
		<Avatars></Avatars>
		<Features></Features>
	</main>
);

export default Home;