import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap'
import styles from './features.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faLanguage, faCode, faFeather, faEllipsis /*faScaleBalanced*/ } from '@fortawesome/free-solid-svg-icons'

const features = [
	{
		id: 1,
		icon: faComments,
		name: "Chatbot",
		description: "You can have a conversation with shapeshifter's many avatars that talk in the style of those characters."
	},
	{
		id: 2,
		icon: faComments,
		name: "Q&A",
		description: "You can ask the base shapeshifter model all sorts of questions, from math and physics to history and literature."
	},
	{
		id: 3,
		icon: faCode,
		name: "Code Generator",
		description: "Enter a description of a function and shapeshifter will generate code for you in any of the available programming languages."
	},
	{
		id: 4,
		icon: faEllipsis,
		name: "More on the way",
		description: "We are working on more cool features that will be announced soon."
	},
	// {
	// 	id: 2,
	// 	icon: faLanguage,
	// 	name: "Translator",
	// 	description: "You can finally have a conversation with anyone! Translate your chat messages in real-time."
	// },
	// {
	// 	icon: faScaleBalanced,
	// 	name: "Moderator",
	// 	description: "Welcome new users, assign roles, set notifications."
	// },
	// {
	// 	id: 4,
	// 	icon: faFeather,
	// 	name: "Transcriber",
	// 	description: "Shapeshifter can listen to your voice conversations and turn them into written text."
	// },
];

const Features = () => {
	return (
		<Container id="features" className="mt-3 px-4">
			<Row>
				<Col xxl={12} className={"border-bottom pb-2 px-0 " + styles.greenBorder}>
					<p className="h2 text-right">Features</p>
				</Col>
			</Row>
			<Row className="py-5">
				{features.map(feature => (
					<Col key={feature.id} className="align-items-start">
						<p className="h4 glow-text">
							<FontAwesomeIcon icon={feature.icon}/>&nbsp;
							{feature.name}
						</p>
						<p>
							{feature.description}
						</p>
					</Col>
				))}
			</Row>
		</Container>
	);
}

export default Features;