import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Header from './components/header/Header'
import Banner from './components/banner/Banner'
import Features from './components/features/Features'
import Avatars from './components/avatars/Avatars'
import Translator from './components/translator/Translator'
import Footer from './components/footer/Footer'

import Home from './routes/Home'
import Faq from './routes/Faq'
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


function App() {
	return (
		<Router>
			<Header/>
			<Routes>
				<Route path="*" element={<Home />} />
				<Route path="/faq" element={<Faq />} />
				<Route path="/about" element={<Home />} />
			</Routes>
			<Footer/>
		</Router>
	);
}

export default App;
