import React, { useEffect, useState } from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap'
import styles from './footer.module.scss'

import { post } from '../helpers/helpers.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import logo from '../../assets/img/shapeshifter_break_gray.svg';

import {
	GoogleReCaptchaProvider,
	GoogleReCaptcha
} from 'react-google-recaptcha-v3';

const className = "nav-link p-0 text-muted text-white";
const activeClassName = className + " active";

const Subscribe = () => {

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');
	const [googleToken, setGoogleToken] = useState(false);

	const subscribe = async () => {

		let data = {
			email: emailAddress,
			token: googleToken
		};

		// const result = await post('https://api.shapeshifter.ai/Subscribe', data);
		const result = await post('https://shapeshifter.ai:8080/Subscribe', data);

		console.log(emailAddress);
		setFormSubmitted(true)
	}

	return (
		<>
			<GoogleReCaptchaProvider reCaptchaKey="6Lfxc7AiAAAAADP3Y_3Uf9rxETtguIsirE2jroog">
				<GoogleReCaptcha onVerify={token => {setGoogleToken(token);}} />
			</GoogleReCaptchaProvider>

			<input id="newsletter_email" onChange={(e) => setEmailAddress(e.target.value)} type="email" className="form-control" placeholder="Email address" value={emailAddress} disabled={formSubmitted}/>
			<button onClick={subscribe} className="btn btn-green" type="button" disabled={formSubmitted}>
				{formSubmitted ? <FontAwesomeIcon icon={faThumbsUp}/> : "Subscribe"}
			</button>
		</>
	)
}

const Footer = () => {
	// const [formSubmitted, setFormSubmitted] = useState("formSubmitted");

	return (
		<Container id={styles.footer} fluid className="text-bg-dark">
			<footer className="container pt-3">
				<Row>
					<Col xxl={3} className="mb-3">
						<ul className="nav flex-column">
							{/*<li className="nav-item mb-2"><NavLink end to="/" className={({ isActive }) => isActive ? activeClassName : className}>Home</NavLink></li>*/}
							{/*<li className="nav-item mb-2"><HashLink to="/#features" className={className}>Features</HashLink></li>*/}
							{/*<li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-muted">Pricing</a></li>*/}
							{/*<li className="nav-item mb-2"><NavLink to="/faq" className={({ isActive }) => isActive ? activeClassName : className}>FAQs</NavLink></li>*/}
							{/*<li className="nav-item mb-2"><NavLink to="/about" className={({ isActive }) => isActive ? activeClassName : className}>About</NavLink></li>*/}
						</ul>
					</Col>
					<Col xxl={4} className="mb-3">
						<Image /*fluid={true}*/ className={styles.logo} src={logo}/>
					</Col>
					<Col xxl={5} className="text-end">
						<form className="text-start">
							<p className="h5">Join the waiting list</p>
							<p>Enter your email below to join the queue and receive the latest news about Shapeshifter</p>
							<div className="d-flex flex-row w-100 gap-2">
								<label htmlFor="newsletter" className="visually-hidden">Email address</label>
								<Subscribe/>
							</div>
							{/*<p className="h5 pt-2">
								Follow Us:&nbsp;
								<a href="#">
									<FontAwesomeIcon icon={ faTwitter} />
								</a>
							</p>*/}
						</form>
					</Col>
				</Row>
				<Row className={"mt-3 pt-3 " + styles.copyrightRow}>
					<p>© 2022 <u><a class={styles.devlink} href="https://mechanic.ink" target="_blank">Mechanic Ink</a></u>. All rights reserved.</p>
				</Row>
			</footer>
		</Container>
	);
}

export default Footer;