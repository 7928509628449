import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap'
import styles from './banner.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faFacebookMessenger, faWhatsapp, faTelegram } from "@fortawesome/free-brands-svg-icons"

import logo from '../../assets/img/shapeshifter_logo.svg';

const Banner = () => {
	return (
		<div id={styles.mainBanner} className="mainBanner position-relative overflow-hidden p-3 p-md-5 text-center bg-light text-bg-light">
			<Col xxl={10} className="mx-auto pt-5 my-5">
				<h1 className="display-4 fw-normal">The chatbot of the future is here.</h1>
				{/*<h1 className="display-4 fw-normal">Talk with the past.<br/> With a chatbot from the future.</h1>*/}
			</Col>
			<Col xxl={10} className="mx-auto my-5">
				<p className="lead fw-normal">Meet shapeshifter, the advanced AI chatbot</p>
				<p className="text-center mt-5">
					Coming soon for<br/>
					<FontAwesomeIcon title="Discord" className={styles.DiscordIcon} icon={faDiscord}/>&nbsp;
					<FontAwesomeIcon title="Messenger" className={styles.MessengerIcon} icon={faFacebookMessenger}/>&nbsp;
					<FontAwesomeIcon title="WhatsApp" className={styles.WhatsappIcon} icon={faWhatsapp}/>&nbsp;
					<FontAwesomeIcon title="Telegram" className={styles.TelegramIcon} icon={faTelegram}/>
				</p>
			</Col>
		</div>
	);
}

export default Banner