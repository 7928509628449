import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap'
import styles from './avatars.module.scss'

import tesla from '../../assets/img/avatars/tesla.webp';
import kennedy from '../../assets/img/avatars/jfk.webp';
import tupac from '../../assets/img/avatars/tupac.webp';
// import newton from '../../assets/img/avatars/newton.webp';
// import hawking from '../../assets/img/avatars/hawking.webp';

const avatars = [
	{
		id: 1,
		image: tesla,
		name:"TeslaBot",
		description:"This avatar is based on Nikola Tesla, one of the greatest inventors in human history."
	},
	{
		id: 2,
		image: tupac,
		name:"TupacBot",
		description:"This is an avatar based on Tupac Shakur, one of the most influential hip-hop artists in the world."
	},
	{
		id: 3,
		image: kennedy,
		name:"KennedyBot",
		description:"This avatar is based on John F. Kennedy, an American politician who served as the 35th president of the United States."
	}
	// {
	// 	id: 2,
	// 	image: newton,
	// 	name:"NewtonBot",
	// 	description:"This is an avatar based on Sir Isaac Newton, one of the greatest mathematicians and physicians of all time."
	// },
	// {
	// 	id: 3,
	// 	image: hawking,
	// 	name:"HawkingBot",
	// 	description:"This avatar is based on Stephen Hawking, the world famours theoretical physicist."
	// }

];

const Avatars = () => {
	return (
		<Container className="mt-3">
			<Row>
				<Col xxl={12} className="text-center pb-3">
					<p className="h1">Talk to the past with a wide selection of avatars</p>
				</Col>

				{avatars.map(avatar => (
					<Col key={avatar.id} xxl={4} className="text-center pb-3">
						<img className={"img-responsive glow " + styles.avatar} height="140" src={avatar.image}/>
						<p className="h2 pt-2 glow-text">{avatar.name}</p>
						<p>{avatar.description}</p>
						
					</Col>
				))}

				<Col xxl={12} className="text-center pb-3">
					<p className="h4 glow-text">More avatars coming soon</p>
				</Col>
			</Row>
		</Container>
	);
}
//<p><a class="btn btn-green" href="#">View details »</a></p>

export default Avatars;