// import React from 'react';
// import styles from './faq.module.scss';
import {Container, Row, Col} from 'react-bootstrap'

const parse = require('html-react-parser');

const items = [
	{
		id: 1,
		question: "What is shapeshifter?",
		answer: "Shapeshifter is a chatbot designed to talk and answer questions as if it were a person from History. It also has some other great features like real-time message translation."
	},
	{
		id: 2,
		question: "Who is shapeshifter for?",
		answer: "Shapeshifter is suitable for anyone! You just need a Discord, Messenger, WhatsApp or Telegram account."
	},
	{
		id: 3,
		question: "Will shapeshifter be free?",
		answer: "Running shapeshifter can end up being quite expensive, but we are working hard on making some of our features free."
	},
	{
		id: 4,
		question: "I can't find the historical figure that I'd like to talk to. Can you add them?",
		answer: 'We would love to add as many avatars as possible. Please send any suggestions to <a href="mailto:suggestions@shapeshifter.ai">suggestions@shapeshifter.ai</a>'
	},
	{
		id: 5,
		question: "Can you add avatars of people from the present time?",
		answer: "We certainly can, but we would need to get approval from them first."
	},
	{
		id: 6,
		question: "Can you create avatars of fictitional characters?",
		answer: "Yes, absolutely! We already have a selection of fictitional avatars in our roster."
	},
	{
		id: 7,
		question: "I have a discord server, can I make money with shapeshifter?",
		answer: "Discord server owners whose users engage with shapeshifter in their server will receive a part of the revenue generated."
	},
	{
		id: 8,
		question: "Can you create custom chatbots for my application?",
		answer: 'We can also provide customised solutions for your product. Please send us an email to <a href="mailto:enquiries@shapeshifter.ai">enquiries@shapeshifter.ai</a>'
	}
];

const Faq = () => (
	<main className="mainBanner">
		<Container fluid>
			<Row className="justify-content-center">
				<Col xxl={6} className="text-center mt-5">
					<h1 className="display-1">FAQs</h1>
				</Col>
			</Row>
			{items.map(item => (
			<Row key={item.id} className="justify-content-center">
				<Col xxl={6}>
					<p>
						<strong>Q</strong>: {item.question}<br/>
						<strong>A</strong>: {parse(item.answer)}
					</p>
				</Col>
			</Row>
			))}
			<Row className="justify-content-center">
				<Col xxl={6} className="text-center mt-5">
					<p className="display-7">
						If you have any other questions, do not hesitate to email us at <a href="mailto:contact@shapeshifter.ai">contact@shapeshifter.ai</a>
					</p>
				</Col>
			</Row>
		</Container>
	</main>
);

export default Faq;